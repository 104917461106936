@import "../../stylesheet/sass/variables";

.google-autocomplete {
  &-wrapper {
    display: flex;
    &-input {
      flex: auto;
    }
    &-button-container {
      display: flex;
      align-items: center;
      background: $input-color-bg;
      border-radius: 0px $input-radius2 $input-radius2 0px;
    }
  }
  &-button {
    right: 0px;
    border: none;
    background: none;
    padding-right: 10px;

    &:focus {
      outline: none;
    }
  }
}
