.profile-dropdown-button {
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  border-radius: 50px;
}

.profile-dropdown-menu {
  font-size: 16px;
  text-decoration: none;
}

.profile-dropdown-menu ul a {
  font-size: 16px;
  text-decoration: none;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navbar-menu {
  flex-grow: 1;
  min-width: 0;
  display: flex;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 20px;
}

.navbar-logo {
  width: auto;
  height: 35px;
  margin-right: 20px;
}

.navbar-logo-compact {
  width: auto;
  height: 35px;
  margin-right: 20px;
}

// Custom for main navbar. This won't affect other menus
.navbar-menu .ant-menu-item-selected::after,
.navbar-menu .ant-menu-item-selected:hover::after,
.navbar-menu .ant-menu-item::after,
.navbar-menu .ant-menu-item:hover::after,
.navbar-menu .ant-menu-submenu::after,
.navbar-menu .ant-menu-submenu:hover::after,
.navbar-menu .ant-menu-submenu-selected::after,
.navbar-menu .ant-menu-submenu-selected:hover::after {
  border-bottom: none !important;
  content: none;
}

.navbar-menu.ant-menu-horizontal {
  border-bottom: none !important;
}
