.assignClaimant .ant-popover-inner-content {
  color: white !important;
  background-color: white !important;
}

.caseManagerButton button,
.caseManagerButton button:hover,
.caseManagerButton button:focus {
  background-color: transparent !important;
  padding: 0 !important;
}

.profileImg {
  padding-right: 5px;
  width: 30px;
  margin-bottom: 2px;
  height:25px !important;
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.grid-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  align-items: baseline;
}
