.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.grid-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    align-items: baseline;
}

.review_lead {
    margin-top: 36px;
    margin-bottom: 36px;
}

.review_lead_help_text {
    margin-bottom: 15px;
}
