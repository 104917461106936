@import '../../stylesheet/sass/variables';

.heading_cards {
    color: $theme-color-two;
    border-bottom: 1px solid $theme-color-two;
    padding-bottom: 5px;
    font-size: $button-font-size-one !important;
    font-weight: $font-weight-four;
}

.heading_cards_2 {
    color: $input-placeholder;
    font-size: $small_button-size;
}

.name_cards_bg {
    background-color: $theme-color-old;
    border-radius: $input-radius3;
}

.name_cards {
    color: $theme-color-two;
    font-weight: $font-weight-four;
    font-size: $button-font-size-count2;
}
.min-height_card{
    min-height:38px;
}
.time_card {
    left: -60px;
    top: 9%;
    color: $input-placeholder;
    font-size: $small_button-size;
    text-align: left;
}
.time_card_2 {
    left: -60px;
    top: 50%;
    color: $input-placeholder;
    font-size: $small_button-size;
    text-align: left;
}

.name_cards_bg2 {
    background-color: $theme-color-two;
    border-radius: $input-radius3;
}

.name_cards2 {
    color: $theme-color-old;
    font-weight: $font-weight-four;
    font-size: $button-font-size-count2;
}