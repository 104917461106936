.v2-data-table {
  margin-top: 36px;
}

.v2-data-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
  text-align: left !important;
  border-radius: 0px !important;
  border: 0px !important;
}

.v2-data-table .ant-table-tbody > tr > td:not(.ant-table-cell-row-hover) {
  background: white !important;
}

.v2-data-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #f8f8f8 !important;
}

.v2-data-table .ant-table-thead > tr > th:last-child {
  text-align: right !important;
}

.v2-data-table .ant-table-tbody > tr > td:last-child {
  text-align: right !important;
}

.v2-data-table .ant-table-tbody > tr {
  cursor: pointer !important;
}

.v2-data-table .ant-table-thead > tr > th {
  background: white !important;
  color: black !important;
  font-weight: 700;
  text-align: left;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6) !important;
}

.v2-data-table .ant-ribbon {
  opacity: 0.75;
}

.profile-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
