.screening_fields_list {
  margin-bottom: 18px;
}

.screening_fields_list .ant-list-item {
  gap: 24px;
  align-items: flex-start;
}

.screening_fields_list .ant-list-header {
  height: 67px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ant-alert.centered-alert {
  justify-content: center;
}

.ant-alert.centered-alert .ant-alert-content {
  flex: none;
}

.screening-list-alert.ant-alert-info {
  background: transparent;
  border: none;
}

/* .prequal_fields_key {
  white-space: nowrap;
} */

.prequal_fields_value {
  text-align: right;
}

.ant-list-header {
  background: #f5f5f5 !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.ai-intake-result .ant-alert-message {
  margin-bottom: 0px;
}
