@import '../../stylesheet/sass/variables';

.boxes_headings {
  color: $theme-color;
  font-weight: $font-weight-five !important;
  min-height: 48px;
}

.background_sq_boxes {
  background-color: $theme-color-three;
  border-radius: $button-radius;
  min-height: 408px;
}

.popover__wrapper2 {
  position: relative;
  left: 8px;
  display: inline-block;
}

.popover__content3 {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -150px;
  transform: translate(0, 10px);
  background-color: #bfbfbf;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}

.popover__content3:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(0% - 15px);
  top: 17px;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  border-color: transparent transparent #3e3e56 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: rotate(90deg);
}

.popover__wrapper2:hover .popover__content3 {
  z-index: 10;
  background: #3e3e56;
  border-radius: 10px;
  color: #fff;
  opacity: 1;
  padding: 15px;
  visibility: visible;
  width: 320px;
  transform: translate(-186px, -35px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message2 {
  text-align: center;
  margin-bottom: 0;
}

.margin_label_end {
  margin-left: 10px !important;
}

.info {
  width: 50%;
  left: 0;
  position: relative;
}

.dws_temp {
  position: absolute;
  right: 12px;
  bottom: 25px;
  width: 10%;
}

.anticon-close {
  font-weight: $font-weight-five !important;
  color: $theme-color-two !important;
}

.ant-table-cell {
  border-bottom: none !important;
}

.ant-table-thead>tr>th {
  background: $theme-color-three !important;
  color: $theme-color !important;
}

.ant-table-tbody>tr>td {
  color: $theme-color-two;
}

.heading_tab {
  margin-bottom: 0 !important;
  line-height: 1.8 !important;
  color: $theme-color-two;
  font-size: $accordion_icon-size !important;
  text-decoration: none !important;
}
.heading_tab:hover{
  color:$theme-color-two !important;
  background:none !important;
}
.item_drp:hover{
background-color: transparent !important;
}

.heading_tab:focus {
  background: $theme-color-three !important;
  background-color: $theme-color-three !important;
  outline: none !important;
}

.item_drp:focus {
  background: $theme-color-three !important;
  background-color: $theme-color-three !important;
}

.item_drp:hover {
  background: $theme-color-three !important;
  background-color: $theme-color-three !important;
}

.white-space {
  white-space: pre;
}
.addmanagegroup{
  overflow: auto;
}

.template_panel {
  width: 350px !important;
}
