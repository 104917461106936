@import "../../stylesheet/sass/variables";

.boxes_headings {
  color: $theme-color;
  font-weight: $font-weight-five !important;
  min-height: 48px;
}

.background_sq_boxes {
  background-color: $theme-color-three;
  border-radius: $button-radius;
  min-height: 408px;
}

.info {
  width: 50%;
  left: 0;
  position: relative;
}

.dws_temp {
  position: absolute;
  right: 12px;
  bottom: 25px;
  width: 10%;
}

.icon_template {
  width: 50%;
  margin-bottom: 4%;
}

.icon_logo {
  width: 25%;
  margin-bottom: 4%;
}

.font-size-ssa {
  font-size: 12px;

  display: block;
}

.document_panel {
  width: 350px !important;
}
