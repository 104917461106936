@import url("https://fonts.cdnfonts.com/css/raleway-5");

$theme-color-three: #f6f6f6;
$theme-color: #77d4b1;
$theme-color-old: #77d4b1;
$theme-color-two: #3e3e56;
$theme-color-ts: transparent;
$button-color-one: #77d4b1;
$button-color-two: #3e3e56;
$input-color-bg: #f6f6f6;
$input-default-bg: #fff;
$input-placeholder: #cfcfcf;
$radio-placeholder: #ccc;

$header-font-size: 20px;
$button-font-size-one: 15px;
$button-font-size-count: 10px;
$button-font-size-count2: 14px;
$label-font-size: 18px;
$label-font-size2: 17px;
$small_button-size: 12px;
$accordion_button-size: 13px;
$accordion_icon-size: 16px;

$font-weight-one: 300;
$font-weight-two: 400;
$font-weight-three: 500;
$font-weight-four: 600;
$font-weight-five: 700;
$font-weight-six: 800;

$background-radius: 15px;
$button-radius: 6px;
$input-radius: 20px;
$input-radius2: 6px;
$input-radius3: 8px;
$radius-50: 50%;

$link-color: #3E8770;

$primary-button-text-color: #182A23;
