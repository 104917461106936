/* See https://stackoverflow.com/questions/51496204/border-gradient-with-border-radius for details on this css */

.ai-card {
  display: inline-block;
  border: none;
  position: relative;
  z-index: 0;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(121, 233, 191, 0.05),
    rgba(255, 229, 146, 0.05)
  );
}
.ai-card:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 1px; /* the border thickness */
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(121, 233, 191), rgba(255, 229, 146));
  mask: linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box;
}

.ai-image {
  width: 32px !important;
}
