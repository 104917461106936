@import "../../stylesheet/sass/variables";

.accordion-item {
  border: none !important;
}

.accordion-button {
  background: $theme-color-three;
  background-color: $theme-color-three !important;
  border-top-left-radius: $input-radius2 !important;
  border-top-right-radius: $input-radius2 !important;
  color: $theme-color-two !important;
  font-size: $accordion_button-size !important;
  font-weight: $font-weight-four !important;
  box-shadow: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.accordion-body {
  background: $theme-color-three;
  background-color: $theme-color-three !important;
  border-bottom-left-radius: $input-radius2 !important;
  border-bottom-right-radius: $input-radius2 !important;
  color: $theme-color-two;
  font-size: $accordion_button-size;
  font-weight: $font-weight-four !important;
  padding-top: 0 !important;
}

.accordion-button::after {
  display: none !important;
}

.icon_font {
  font-size: $header-font-size !important;
  font-weight: $font-weight-six;
  color: $theme-color;
}

.open_acc {
  color: $input-placeholder !important;
  font-size: $small_button-size !important;
  white-space: nowrap;
}

.due_date {
  margin-right: 12px;
}

.button-primary-custom {
  color: $theme-color-two !important;
  font-size: $accordion_button-size !important;
  font-weight: $font-weight-four !important;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.notes_cal {
  width: 20px;
}

.outline_pinned {
  border: 2px solid $theme-color !important;
}

.taskId {
  border: 2px solid #e4a461 !important;
}

.accordion-button .icon_font2 {
  display: none;
}

.collapsed .icon_font2 {
  display: block !important;
}

.collapsed .icon_font {
  display: none !important;
}

.icon_font2 {
  font-size: 18px !important;
  font-weight: 800;
  color: $theme-color;
}

.accordion_attachmant {
  color: #c7c7c7;
}

.accordion_attachmant {
  font-size: 12px;
}

.notes_attach {
  width: 3%;
  margin-left: 5px;
}

.icons_all {
  display: flex;
  text-decoration: none !important;
  align-items: center;
}

.bg_input_texarea {
  border: none !important;
  background-color: #fff !important;
  border-radius: $input-radius2;
}

.ant-text-input {
  border-radius: 6px;
  height: 44px !important;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 5px solid #747497;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.back-top-con .ant-back-top-content {
  background-color: $theme-color;
  color: $primary-button-text-color;
}

.back-top-con .ant-back-top-content:hover {
  background-color: $theme-color;
  color: $primary-button-text-color;
}

.notes_parent {
  max-height: 250px;
  overflow: auto !important;
}
