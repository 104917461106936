@import "./sass/variables";

body {
  background-color: $theme-color-three;
  font-family: "Raleway", sans-serif;
}

// GLOBAL CLASSES
//*****************

.bg_row {
  background: $input-default-bg;
  border-radius: $background-radius;
  padding: 1% 1%;
}

// ************
// DROPDOWN CSS
// ************

.arrow_image_drp2 {
  width: 4%;
  float: right;
  margin-top: 8px;
}

.dropdown_button {
  background-color: $input-color-bg !important;
  font-size: $font-weight-three !important;
  color: $button-color-two !important;
  border: none !important;
  padding: 10px !important;
  border: none !important;
  border-radius: $button-radius !important;
}

.dropdown_button::after {
  display: none;
}

.button_clients::after {
  display: none;
}

.dropdown_button_select::after {
  display: none;
}

.w-30 {
  width: 30%;
}

.ant_drp2 .dropdown-menu {
  background: $theme-color-two !important;
  background-color: $theme-color-two;
  z-index: 10000;
}

.ant_drp2 .dropdown-item:focus,
.ant_drp2 .dropdown-item:hover {
  background: $theme-color !important;
  color: $input-default-bg !important;
}

#NotificationTemplateForm_content {
  max-height: 100px;
  overflow: auto !important;
}

.ant_drp2 h5 {
  color: #77d4b1 !important;
}

.ant_drp .dropdown-menu {
  background-color: $theme-color;
  background: $theme-color;
  z-index: 10000;
}

.ant_drp .dropdown-item:focus,
.ant_drp .dropdown-item:hover {
  background: $theme-color-two !important;
  color: $input-default-bg !important;
}

.custom_leads {
  background-color: $theme-color-two !important;
  background: $theme-color-two !important;
  color: $theme-color-old !important;
  padding: 5px !important;
}

.leads_icon {
  margin-right: 10px;
  font-weight: $font-weight-six;
}

.edit_btn_pre {
  background: none;
  border: none;
}

.customer_icon {
  margin-right: 10px;
  font-weight: $font-weight-six;
}

.custom_customer {
  background: $theme-color-old !important;
  background-color: $theme-color-old !important;
  color: $theme-color-two !important;
  padding: 5px !important;
}

.accordion-header button {
  justify-content: space-between;
}

.dropdown-item:focus h5,
.dropdown-item:hover h5 {
  color: $input-default-bg !important;
}

.ant-popover-arrow-content:before {
  background: $theme-color-two !important;
  background-color: $theme-color-two !important;
}

.ant-popover-title {
  color: #fff;
  background-color: $theme-color-two;
  border: none !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ant-popover-inner-content {
  color: #fff;
  background-color: $theme-color-two;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dropdown_button:focus {
  border: none !important;
  box-shadow: none !important;
  background-color: $button-color-one !important;
  font-size: $font-weight-three !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
}

.activemenu {
  border: none !important;
  box-shadow: none !important;
  background-color: $button-color-one !important;
  font-size: $font-weight-three !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
}

.anchor_all a {
  color: $theme-color-two !important;
}

.anchor_all:hover a {
  color: #fff !important;
}

.circle_count {
  left: 50%;
  background: #f10909;
  height: 16px;
  min-width: 16px;
  border-radius: 20px;
  display: flex;
  width: auto;
  padding: 5px;
  justify-content: center;
  color: #fff;
  position: absolute;
  align-items: center;
  font-size: 12px;
}

.background_button_dl {
  background: #fff !important;
  background-color: #fff !important;
}

.button_css_bullets {
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
  transform: rotate(4.7061rad);
  padding: 0 !important;
}

.form_edit {
  background: transparent;
  border: none;
  text-align: right;
  float: right;
}

.input_style2 {
  border: none !important;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border: none !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.header_bg {
  background: transparent !important;
}

.dropdown_button:hover {
  border: none !important;
  box-shadow: none !important;
  background-color: $button-color-one !important;
  font-size: $font-weight-three !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
}

.icons_pannel {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.button_add_padding:hover {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.button_add_padding:focus {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.dropdown_button_select {
  background-color: $button-color-one !important;
  font-size: $font-weight-three !important;
  color: $button-color-two !important;
  border: none !important;
  padding: 10px !important;
  border: none !important;
  border-radius: $button-radius !important;
}

.dropdown_button_select:focus {
  background-color: $button-color-one !important;
  font-size: $font-weight-three !important;
  color: $button-color-two !important;
  border: none !important;
  box-shadow: none !important;
}

.dropdown_button_select:active {
  background-color: $button-color-one !important;
  font-size: $font-weight-three !important;
  color: $button-color-two !important;
  border: none !important;
  box-shadow: none !important;
}

.dropdown_button_select:hover {
  background-color: $button-color-one !important;
  font-size: $font-weight-three !important;
  color: $button-color-two !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-table-thead > tr > th {
  background: #77d4b1 !important;
  color: #182a23 !important;
  margin: 3px;
  border-radius: 14px !important;
  text-align: center;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: #fff;
  border-style: solid;
  border-width: 4px;
}

.ant-table-tbody > tr > td {
  color: #3e3e56;
  border-radius: 14px !important;
  background: #f6f6f6;
  gap: initial;
  display: table-cell;
  margin: 5px;
  text-align: center;
}

.ant-table-cell {
  border-bottom: 4px solid #fff !important;
}

td .d-flex {
  justify-content: center;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #f6f6f6;
}

.ant-table-tbody > tr.ant-table-row:hover > .des-2-pr-2,
.ant-table-tbody > tr > .des-2-pr-2.ant-table-cell-row-hover {
  background: #f6f6f6 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > .des-2-pr-3,
.ant-table-tbody > tr > .des-2-pr-3.ant-table-cell-row-hover {
  background: #fff !important;
}

.font-size-apply {
  font-size: 13px !important;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: $theme-color-three;
  border-radius: $radius-50;
}

.container_radio input {
  position: absolute;
  opacity: 0;
}

/* On mouse-over, add a grey background color */
.container_radio:hover input ~ .checkmark {
  background-color: $radio-placeholder;
}

/* When the radio button is checked, add a blue background */
.container_radio input:checked ~ .checkmark {
  background-color: $theme-color-three;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_radio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: $radius-50;
  background: $theme-color;
}

.container_radio {
  display: block;
  position: relative;
  padding-left: 25px;

  cursor: pointer;
  font-size: 17px;
  color: #77d4b1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.css-1hwfws3 {
  border: none !important;
}

.cursorPointer {
  cursor: pointer;
}

.oldLeadElement {
  background-color: #f10909;
}

.oldLeadElement > p {
  color: #fff !important;
}

.zindex99 {
  z-index: 99;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $theme-color !important;
}

.anticon-search svg {
  width: 20px;
  height: 20px;
}

.ant-radio-inner:after {
  background-color: $theme-color;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $theme-color-two !important;
  background: $theme-color-two !important;
  border: none !important;
  border-radius: 3px !important;
  margin-left: 2%;
  margin-right: 2%;
  color: $input-default-bg !important;
}

.ant-radio-button-wrapper {
  background-color: $theme-color-three !important;
  background: $theme-color-three !important;
  border: none !important;
  border-radius: 3px !important;
  color: #fff !important;
  margin-left: 2%;
  margin-right: 2%;
  color: $theme-color-two !important;
}

.ant-radio-button-wrapper:not(:first-child):before {
  display: none !important;
}

.ant-table-thead {
  white-space: nowrap;
}

.ant-table-cell div {
  white-space: nowrap;
}

@media screen and (max-width: 920px) {
  .ant-modal-body {
    overflow: scroll;
  }
}

.ant-picker {
  width: 100%;
}

.ant-radio-group-solid {
  width: 200px;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):before {
  display: none !important;
}

.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: $theme-color !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 1;
}

// .formContainer{
//     height:calc(100vh - 2em);
//     width: 100%;
//     justify-content: center;
//     align-content: center;

//       .formFieldContainer{
//           padding:20px;
//           border-radius:10px;
//           border: 2px solid #8080804f;
//       }
//       .text-end{
//         display: block;
//         text-align: end;
//     }
// }

// .instructions{
//     font-weight: bold;
//     font-size: 14px;
// }

.class_loader {
  width: 100%;
  min-height: 70vh;
}

.loaderLeftPanelHeight {
  min-height: 5vh !important;
}

.class_loaderloadmore {
  width: 10%;
  // min-height: auto;
  // left: 50%;
  //     position: absolute;
  // bottom: 20px;
}

.loader2 {
  border: 8px solid #f6f6f6;
  border-radius: 50%;
  border-top: 8px solid #77d4b1;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loaderLeftPanelHeight {
  min-height: 5vh !important;
}

.class_loaderloadmore {
  width: 10%;
  // min-height: auto;
  // left: 50%;
  //     position: absolute;
  // bottom: 20px;
}

.loader2 {
  border: 8px solid #f6f6f6;
  border-radius: 50%;
  border-top: 8px solid #77d4b1;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loaderLeftPanelHeight {
  min-height: 5vh !important;
}

.class_loaderloadmore {
  width: 10%;
  // min-height: auto;
  // left: 50%;
  //     position: absolute;
  // bottom: 20px;
}

.loader2 {
  border: 8px solid #f6f6f6;
  border-radius: 50%;
  border-top: 8px solid #77d4b1;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.margin-auto {
  margin: auto;
}

.css-1wy0on6 {
  display: none !important;
}

.css-1hwfws3 {
  width: 90%;
  background: #f6f6f6 !important;
  border-radius: 6px !important;
  border: none !important;
  height: 34px !important;
  padding: 0 2%;
}

.css-yk16xz-control {
  border: none !important;
}

.margin_mobile {
  margin-top: 2%;
  margin-bottom: 3%;
}

.click_doc {
  color: $theme-color;
  font-weight: $font-weight-five;
}

.fill_description {
  font-weight: $font-weight-four;
  color: $theme-color-two;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $theme-color !important;
  border-color: $theme-color;
}

.mobile_flex {
  display: flex;
}

.form_flex {
  display: flex;
}

.label_form_qs {
  margin-left: 10px;
  color: $theme-color-two;
  font-weight: $font-weight-four;
  white-space: nowrap;
}

.form_flex2 {
  display: flex;
  justify-content: space-between;
}

.ant-checkbox:hover {
  border-color: $theme-color;
}

.loader {
  border: 8px solid $theme-color-three;
  border-radius: 50%;
  border-top: 8px solid $theme-color;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.button_save_customer {
  background-color: $theme-color-two;
  color: $input-default-bg;
  border-radius: $button-radius;
}

.note_add div {
  width: auto;
  border-radius: $input-radius2;
  border: none !important;
  top: auto !important;
  margin-top: 0 !important;
  max-height: 150px;
  min-height: 92px;
}

.note_add .ant-form-item-control-input {
  width: 100%;
  border-radius: $input-radius2;
  border: 1px solid #d9d9d9 !important;
  top: auto !important;
  margin-top: 0 !important;
  max-height: 150px;
  min-height: 92px;
}

.note_add .ant-form-item-control-input-content {
  padding-left: 9px !important;
}

.accordion-body pre {
  overflow: inherit;
  white-space: break-spaces;
  padding-right: 25px !important;
}

#NotesForm textarea {
  overflow: auto !important;
}

.note_add textarea {
  width: 100%;
  background: $theme-color-three;
  border-radius: $input-radius2;
  border: none !important;
  padding: 6px;
  max-height: 150px;
  min-height: 92px;
  overflow-y: scroll !important;
}

.css-1hwfws3 {
  height: 44px !important;
}

.note_add textarea:focus-visible {
  outline: none !important;
}

.note_add .ant-form-item {
  margin-top: 0 !important;
}
.right-0 {
  right: 0 !important;
}

.margin_comment {
  margin-bottom: 0.5rem !important;
}

.notes_parent {
  font-size: 12px !important;
}

.note_add ul {
  z-index: 1;
  background-color: white;
  min-width: 130px !important;
  width: 130px !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10px;
}

.note_add li {
  cursor: pointer;
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.note_add li:hover {
  background-color: rgb(206, 228, 229);
  position: relative;
  z-index: 999;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $theme-color-three;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $theme-color-two;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $theme-color-two;
}

.accordion-button {
  z-index: 0 !important;
}

.note_add input {
  width: 100%;
  background: $theme-color-three;
  border-radius: $input-radius2;
  border: none;
  height: 34px;
  padding: 0 2% !important;
  border: none !important;
}

#NotesForm_plainText {
  border-radius: 6px !important;
}

.input:focus-visible {
  outline: none !important;
}

.text-left {
  text-align: left !important;
}

.button_leads_submit {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 7px 6% !important;
  background-color: $theme-color-two !important;
  color: $theme-color !important;
  border: none !important;
  border-radius: 6px !important;
}

.button_clients_cancel {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 7px 6% !important;
  background-color: $theme-color !important;
  color: $theme-color-two !important;
  border-radius: 6px !important;
  border: none !important;
}

.displayNone {
  display: none;
}

.ant-spin-dot-item {
  background-color: $theme-color !important;
}

.ant-spin {
  color: $theme-color !important;
}

.plus_position {
  border: none;
  background: transparent;
}

.display_notification {
  display: grid;
  text-align: center;
}

.display_notification1 {
  display: grid;
  text-align: right;
}

.display_notification2 {
  // display: none;
  text-align: right;
  font-weight: 100px;
}

#notifications-popover .ant-popover-inner-content {
  max-height: 360px;
  overflow-y: scroll;
}

.read-notification {
  color: #77d4b1;
}

.notificationPanel {
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 1rem;
}

.error {
  color: red;
  padding-left: 5px;
}

.minus_sign {
  top: 15px;
  right: -15px;
}

.input_style3 {
  width: 100%;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.css-2b097c-container div {
  z-index: 99;
}

.width_image_form {
  width: 8%;
}

.mobile_responsive_field {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.height_btn {
  height: 38px !important;
}

.height_btn:hover {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.height_btn:focus {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.width_cust_act {
  width: 216px;
}

.width-page-global {
  width: 100% !important;
}

.height_btn:focus-visible {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

@media screen and (min-width: 920px) {
  .desktop_scroll {
    overflow: scroll !important;
    flex-wrap: inherit !important;
    height: 80vh;
  }
}

@media only screen and (max-width: 767px) {
  .mobie_search {
    margin-top: 4%;
  }
  .min-max-design {
    display: block !important;
  }
  .mobile_responsive_field {
    margin-bottom: 10px !important;
  }
  .height_btn {
    margin-top: 5% !important;
  }

  .mobile_responsive_field {
    display: block !important;
  }

  .display_fmobile {
    display: flex;
    align-items: center;
  }

  .form_flex {
    display: block !important;
  }

  .mobile_flex {
    display: block !important;
  }

  .heading_cards {
    margin-top: 15px;
  }

  .form_flex2 {
    display: block !important;
  }

  .time_card {
    position: relative !important;
    left: 0 !important;
    margin-bottom: 2px !important;
  }

  .time_card_2 {
    position: relative !important;
    left: 0 !important;
    margin-bottom: 2px !important;
  }

  .time_card_2 {
    position: relative !important;
    left: 0 !important;
    margin-bottom: 2px !important;
  }

  .mobile_view_chk_input {
    width: 8% !important;
  }

  .mobile_view_chk_input-2 {
    width: 50% !important;
  }

  .mobile_view_chk {
    width: 42% !important;
  }

  .eye_icon {
    position: absolute;
    right: -8%;
    top: -30px;
  }
}

.maping_button {
  background: none !important;
  background-color: transparent !important;
  color: $theme-color;
  border: none !important;
}

.deactivate_button {
  color: #f10909 !important;
  font-size: 12px;
  font-weight: 700;
}

.maping_button:hover {
  background: none !important;
  background-color: transparent !important;
  color: $theme-color !important;
  text-decoration: underline;
}

.filled_button {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 4px 2% !important;
  background-color: #77d4b1 !important;
  color: #3e3e56 !important;
  border-radius: 6px !important;
  border: 2px solid #77d4b1 !important;
}

.border_button {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 4px 2% !important;
  background: transparent !important;
  background-color: transparent !important;
  color: #3e3e56 !important;
  border-radius: 6px !important;
  border: 2px solid #77d4b1 !important;
}

.ant-radio {
  border: 1px solid #77d4b1 !important;
  border-radius: 50%;
}

.minus_icon {
  position: absolute;
  right: -54px;
  top: 15px;
}

.info_clm {
  width: 9px;
  position: absolute;
  right: 21%;
}

.popover__wrapper {
  position: relative;

  display: inline-block;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -152px;
  transform: translate(0, 10px);
  background-color: #bfbfbf;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}

.right-16 {
  right: 16% !important;
}

.react-switch-handle {
  background: #77d4b1 !important;
}

.react-switch-bg {
  background: #3e3e56 !important;
}

.react-switch-handle {
  height: 24px !important;
  width: 24px !important;
}

.react-switch-bg {
  height: 26px !important;
  width: 55px !important;
  margin: 0px !important;
  position: relative;

  border-radius: 14px;
  cursor: pointer;
  transition: background 0.25s ease 0s;
}

.react-switch-bg :first-child {
  height: 22px !important;
  width: 24px !important;
  position: relative;
  top: 1px !important;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.25s ease 0s;
}

.react-switch-bg svg {
  position: absolute;
  top: 3px !important;
  width: 22px !important;
  height: 22px !important;
  right: 0px !important;
}

.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(0% - -125px);
  top: 17px;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  border-color: transparent transparent #3e3e56 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: rotate(-90deg);
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  background: #3e3e56;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  width: 130px;
  transform: translate(178px, -35px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__content2 {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -150px;
  transform: translate(0, 10px);
  background-color: #bfbfbf;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}

.popover__content2:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(0% - -125px);
  top: 17px;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  border-color: transparent transparent #77d4b1 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: rotate(-90deg);
}

.popover__wrapper:hover .popover__content2 {
  z-index: 10;
  background: #77d4b1;
  border-radius: 8px;
  color: #3e3e56;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  width: 130px;
  transform: translate(178px, -30px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
  text-align: center;
  font-size: 12px;
  margin-bottom: 0;
}

.info_image2 {
  width: 38%;
}

.subject_input div {
  background: #f6f6f6;
  border-radius: 6px;
  display: block;
  left: 0px !important;
}

.subject_input strong {
  display: none;
}

.vertical-super {
  vertical-align: super;
}

.margin_drp {
  margin-left: 10px;
}

.height_temp_box {
  min-height: 300px !important;
}

.icons_new {
  width: 20px;
  height: 15px;
}

.es_icon {
  width: 87%;
}

.top_icon {
  top: 30% !important;
}

.cards_bottom:last-child {
  margin-bottom: 100px;
}

.arrow_image_drp-global {
  width: 6%;

  top: 47%;

  right: 5%;
  position: absolute;
  z-index: 999;
}

.arrow_image_drp-notes {
  width: 3%;

  top: 39%;

  right: 5%;
  position: absolute;
  z-index: 999;
}

.des-2-pr-2 {
  background: #fff !important;
  text-align: center !important;
}

th.des-2-pr-2 {
  display: none;
}

th.des-2-pr-3 {
  display: none;
}

th.des-2-pr {
  display: none;
}

.taskId {
  border-radius: 6px;
}

.taskId button {
  border-radius: 6px;
}

.outline_pinned {
  border-radius: 6px;
}

.outline_pinned button {
  border-radius: 6px;
}

.data-table-ant .ant-table table {
  width: 57% !important;
  margin: 3% auto !important;
}

.ant-table-tbody > tr.ant-table-row:hover > .des-2-pr-2,
.ant-table-tbody > tr > .des-2-pr-2.ant-table-cell-row-hover {
  background: #fff;
}

.des-2-pr-3 {
  background: #fff !important;
  width: 130px;
  text-align: left !important;
  padding: 16px 0 !important;
}

.des-2-pr-2 {
  background: #f6f6f6 !important;
  width: 400px;
  padding: 16px 10px !important;
}

.ant-popover.ant-popover-placement-bottom {
  z-index: 10000 !important;
}

.ant-back-top {
  bottom: 22px !important;
  left: 60px !important;
}

.rbc-slot-selection {
  width: 100% !important;
}

.calendar-event-custom-popover .ant-popover-inner-content {
  background-color: #f0f2f5; /* Replace with your desired background color */
  color: black;
  padding-bottom: 5px;
}

.calendar-event-custom-popover .ant-popover-inner {
  padding: 15px;
  background-color: #f0f2f5;
}

.calendar-event-custom-popover .ant-popover-title {
  background-color: #f0f2f5; /* Replace with your desired background color */
  color: black;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pointers-div p:last-child {
  text-align: right;
}

.pointers-div .min_hg_text {
  margin-left: 70px;
}

.pointers-div p:first-child {
  min-width: 350px;
  max-width: 350px;
}

.checked-box {
  margin-left: -16px;
}

.checked-box svg {
  border-radius: 6px;
}

.indent-one {
  text-indent: -12px;
  margin-left: 12px;
}

.count-int {
  margin-right: 2px;
}

.custom-view {
  white-space: break-spaces;
  line-height: 18px !important;
  word-wrap: break-word;
}

.custom-drp {
  padding: 10px 16px !important;
}

.basic-view .dropdown-menu {
  min-width: 178px;
}
.add-class-eighty {
  width: 88%;
}
.width-drp-btn {
  width: 87%;
}
.padding-add-btn {
  padding-left: 13px !important;
  padding-right: 13px !important ;
}
@media only screen and (max-width: 768px) {
  .mobie_search {
    margin-top: 4%;
  }
  .min-max-design {
    display: block !important;
  }
  .mobile_responsive_field {
    margin-bottom: 10px !important;
    padding: 0;
    margin-left: 12px;
  }
  .arrow_image_drp-global {
    width: 2% !important;
  }
  .mb-res-css {
    display: flex !important;
    width: 100%;
  }
}

/* Enterprise Assignment */
#enterprise-assignment {
  margin-top: 24px;
}

#enterprise-assignment-body {
  border: 1px solid #808080;
  border-radius: 8px;
  padding: 24px 20px;
}

#enterprise-assignment-facility-name {
  padding: 24px 20px;
}

#enterprise-assignment-body button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

#enterprise-assignment-body button:hover {
  text-decoration: none;
}

#enterprise-assignment-body a {
  margin-top: 16px;
  display: block;
}

#enterprise-assignment-body svg {
  height: 14px;
  margin-left: 8px;
}

.secondary-button {
  color: #fff;
  background: #3e3e56;
  border-color: #3e3e56;
}

.secondary-button:hover {
  color: #fff !important;
  background: #4e4e6b !important;
  border-color: #4e4e6b !important;
}

a {
  color: $link-color;
  text-decoration: none;
}
