.main-layout {
  padding: 10px 0;
  min-height: 100vh;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  width: 100%;
}

.main-content-layout {
  padding: 0 40px;
}

.breadcrumbs-container {
  margin: 20px 0;
}

.ant-menu-item a {
  text-decoration: none;
}

.ant-menu-item a:hover {
  text-decoration: none;
}

.ant-layout-header {
  border-bottom: 1px solid lightgrey;
  height: 80px;
}

.ant-menu-title-content {
  font-size: 16px;
  font-weight: 400;
}

.profile-content {
  padding-left: 30px;
  max-width: 1800px;
}

.ant-menu-item-selected span,
.ant-menu-submenu-selected span {
  font-weight: 600;
}
