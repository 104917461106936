@import '../../stylesheet/sass/variables';

.height_info{
  min-height:130px !important;
  padding-top: 10px;
}
.info_det{
  color:$theme-color-two;
  font-size:$button-font-size-count2;
  font-weight:$font-weight-four;
}
.color-anc{
  color:#3e3e56 !important;
}