@import '../stylesheet/sass/variables';


.button_leads {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-two !important;
  color: $button-color-one !important;
  border: none !important;
  border-radius: $button-radius !important;
}

.button_leads:focus {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-two !important;
  color: $button-color-one !important;
  background: $button-color-two !important;
  border: none !important;
  outline: none !important;
  border-radius: $button-radius !important;
  box-shadow: none !important;
}

.button_leads:hover {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-two !important;
  color: $button-color-one !important;
  border: none !important;
  outline: none !important;
  background: $button-color-two !important;
  box-shadow: none !important;
  border-radius: $button-radius !important;
}

.button_leads:active {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-two !important;
  background: $button-color-two !important;
  color: $button-color-one !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: $button-radius !important;
}

.button_global_tasks {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  background-color: $button-color-two !important;
  color: $button-color-one !important;
  border: none !important;
  border-radius: $button-radius !important;
}

.button_case_manage {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.count_lead {
  font-size: $button-font-size-count;
  position: absolute;
  left: 5px;
  top: 3px;
  font-weight: $font-weight-four;
}



.button_clients2 {
  font-size: $small_button-size !important;
  font-weight: $font-weight-five !important;
  padding: 7px 3% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.button_clients2:hover {
  font-size: $small_button-size !important;
  font-weight: $font-weight-five !important;
  padding: 7px 3% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  background: $button-color-one !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_clients2:focus {
  font-size: $small_button-size !important;
  font-weight: $font-weight-five !important;
  padding: 7px 3% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  background: $button-color-one !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_clients2:active {
  font-size: $small_button-size !important;
  font-weight: $font-weight-five !important;
  padding: 7px 3% !important;
  background-color: $button-color-one !important;
  background: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  border-radius: $button-radius !important;
  box-shadow: none !important;
}

#NotificationTemplateForm_content {
  min-height: 44px !important;
  background: $theme-color-three !important;
  border-radius: 6px !important;
  font-size: 1rem !important;
  font-family: "Raleway", sans-serif !important;
}

#NotificationTemplateForm_subject {
  background: $theme-color-three;
  border: none !important;
  height: 44px !important;
  font-size: 1rem !important;
  background-color: $theme-color-three;
  padding: 10px !important;
}

.button_go {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 3% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.button_go:hover {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 3% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  background: $button-color-one !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_go:focus {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 3% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  background: $button-color-one !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_go:active {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 3% !important;
  background-color: $button-color-one !important;
  background: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  border-radius: $button-radius !important;
  box-shadow: none !important;
}

.name_log {
  color: $theme-color-two;
  font-weight: $font-weight-five;
}

.phone_log {
  color: $theme-color-two;
  font-weight: $font-weight-three;
}

.save_num {
  color: $input-placeholder;
  font-size: $button-font-size-count2;
}

.mail_log {
  color: $theme-color-two;
  font-weight: $font-weight-two;
}

.activity_bar {
  background-color: $input-color-bg;
  height: 483px;
  overflow: auto;
}

.activity_heading {
  color: $theme-color-two;
  font-weight: $font-weight-five;
}

.text_activity {
  color: $theme-color-two;
  font-size: $button-font-size-count2;
}

.plus_position {
  position: absolute;
  font-weight: $font-weight-six;
  color: $button-color-one;
  right: 15px;
  top: 0;
  font-size: $header-font-size;
}

.label_weight {
  font-weight: $font-weight-five;
  color: $theme-color-two;
  font-size: $label-font-size;
}

.heading_input {
  color: $theme-color;
  font-size: $label-font-size2;
}

.text_form_label {
  color: $theme-color;
  font-size: $label-font-size2;
}

.button_yes {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 5px 3% !important;
  background-color: $theme-color-three !important;
  color: $input-placeholder !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.button_yes:hover {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 5px 3% !important;
  background-color: $button-color-two !important;
  color: $input-default-bg !important;
  background: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_yes:focus {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 5px 3% !important;
  background-color: $button-color-two !important;
  color: $input-default-bg !important;
  background: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_yes:active {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 5px 3% !important;
  background-color: $button-color-two !important;
  color: $input-default-bg !important;
  background: $button-color-two !important;
  border-radius: $button-radius;
  border: none !important;
  outline: none !important;
  border-radius: $button-radius;
  box-shadow: none !important;
}

.button_selected_state {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 5px 3% !important;
  background-color: $theme-color-two !important;
  color: $input-default-bg !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.button_selected_state:hover {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 5px 3% !important;
  background-color: $button-color-two !important;
  color: $input-default-bg !important;
  background: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}


.button_nonselected_state {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 5px 3% !important;
  background-color: $theme-color-three !important;
  color: $input-placeholder !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.button_nonselected_state:hover {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 5px 3% !important;
  background-color: $theme-color-three !important;
  color: $input-placeholder !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_clients_pure {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.button_clients {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.button_clients:hover {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  background: $button-color-one !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_clients:focus {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  background: $button-color-one !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_clients:active {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 7px 12% !important;
  background-color: $button-color-one !important;
  background: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  border-radius: $button-radius !important;
  box-shadow: none !important;
}



//cards css

.border-none-class {
  border: none !important;
  background-color: $theme-color-three !important;
  background: $theme-color-three !important;
}

.left_column {
  width: 500px !important;
}

.right_column {
  padding-left: 20px !important;
}
