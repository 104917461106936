@import "../../stylesheet/sass/variables";

.button_login {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 3px 12% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
}

.button_login:hover {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 3px 12% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  background: $button-color-one !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_login:focus {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 3px 12% !important;
  background-color: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  background: $button-color-one !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.button_login:active {
  font-size: $button-font-size-one !important;
  font-weight: $font-weight-four !important;
  padding: 3px 12% !important;
  background-color: $button-color-one !important;
  background: $button-color-one !important;
  color: $button-color-two !important;
  border-radius: $button-radius !important;
  border: none !important;
  outline: none !important;
  border-radius: $button-radius !important;
  box-shadow: none !important;
}

.input_style_login {
  width: 100%;
  background: $theme-color-three;
  border-radius: $input-radius2;
  border: none;
  height: 34px;
  padding: 0 2%;
}

.input_style_login::placeholder {
  color: $input-placeholder;
  border: none;
  outline: none;
  font-size: $button-font-size-count2;
}

.input_style_login:focus-visible {
  border: none;
  outline: none;
}

.label_weight label {
  font-weight: $font-weight-five !important;
  color: $theme-color-two !important;
  font-size: $label-font-size !important;
}

.login_form {
  padding: 3% 2%;
  border-radius: $background-radius;
  background: $input-default-bg;
}

.heading_app {
  color: $theme-color-two;
  font-weight: $font-weight-five;
}

.login_bg {
  padding-top: 7%;
}
