.fax-main-content {
  margin-top: 30px;
}

.fax-actions {
  display: flex;
  align-items: center;
  float: right;
  gap: 20px;
}

.fax-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.nav-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 16px;
}

.final-nav-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
}

.autofill-section {
  padding-bottom: 20px;
}

.view-document-icon {
  display: flex;
  float: right;
}

.delete-fax-button {
  color: red !important;
  margin-right: auto !important;
}

.v2-data-table tbody > tr.no-hover > td {
  padding: 15px 0px !important;
}

.v2-data-table tbody > tr.no-hover:hover > td {
  background: inherit !important;
  cursor: default !important;
}

.fax-preview-container > div {
  margin-top: 0 !important;
}

.fax-preview-container h3 {
  font-size: 1rem;
  font-weight: 450;
}

.ant-steps {
  margin: 20px 0;
}

.self-serve-section-progress .ant-progress-text {
  text-align: right;
  margin-inline-start: 0px;
}
