.notification-text {
  font-size: 12px;
}

.notification-badge {
  left: -10px;
}

.notification-close-icon {
  font-size: 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.notification-bell-icon {
  font-size: 20px;
  color: #77d4b1;
  border-radius: 50px;
}

.notification-bell-icon:hover {
  color: #77d4b1 !important;
}
