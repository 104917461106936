.reassignment-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.reassignment-header {
  text-align: center;
  margin-bottom: 30px;
}

.reassignment-step {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.reassignment-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.filter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin: 20px 0;
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reassignment-form {
  margin-top: 24px;
}

.reassignment-actions {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 12px;
}

.count-display {
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.case-manager-row {
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 12px;
} 