@import '../../stylesheet/sass/variables';

.wrapper {
    margin-top: 80px;
    margin-bottom: 80px;
    width: 40%;
    margin: 7% auto;
}

.form-signin {
    background-color: #f7f7f7;
    padding: 10% 17%;
    margin: 0 auto;
    border-radius: 5px;
}

.entertext {
    font-size: 18px;
    letter-spacing: 0px;
    color: #626164;
    font-weight: 500;
    text-align: center;
    padding-top: 4%;
    padding-bottom: 5%;
}

input[type="text"],
input[type="number"],
input[type="email"] {
    width: 100%;
}
.cancel_form{
    font-size:1rem !important;
}