#claim-area {
  margin-top: 36px;
  margin-bottom: 36px;
}

.undo-button {
  color: #58ad90;
  font-size: 12px;
}

.brand-header {
  font-size: 20px !important;
  font-weight: 600 !important;
}

#claim-cards-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 18px;
}

.claim-card {
  border-radius: 8px;
  border: 1px solid #808080;
  color: #3e3e56;
}

.claim-not-eligible-card {
  border-radius: 8px;
  border: 1px solid #808080;
  color: #3e3e56;
  background-color: rgba(0, 0, 0, 0.04);
}

.claim-badge {
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  font-size: 14px;
}

.claim-title {
  margin: 15px 0px !important;
}

.not-eligible-text {
  color: rgba(0, 0, 0, 0.25) !important;
}

#intake-note-mentions-input,
.intake-note-area__highlighter {
  min-height: 72px !important;
  padding: 16px !important;
}

label.intake-form-label {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
  margin-top: 20px;
  margin-bottom: 2px;
}

/* New AssignmentDropdown Component */

.assignment-component {
  display: flex;
  gap: 15px;
}
.assignment-dropdown-search-container {
  position: sticky;
  top: -10;
  z-index: 1000;
  background-color: transparent;
  padding: 10px;
}

.assignment-dropdown-search-container > input {
  background-color: transparent !important;
}

.assignment-dropdown-options-list {
  max-height: 200px !important;
  max-width: 300px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.auto-assign-option {
  border-bottom: 1px solid #3e3e56 !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.dropdown-tab {
  width: 5px;
  margin-left: 5px;
}

.active-link {
  border-left: 3px solid green;
}

.expired-link {
  border-left: 3px solid #f8d7da;
}

.registration_link_info {
  padding: 0 10px;
  font-size: 14px;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 500ms;
}

.link-header {
  font-size: 12px;
  margin-bottom: 8px;
}

.link-details {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

label.registration_link_label {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
  margin-top: 16px;
  margin-bottom: 8px;
}

.secure_registration_link_section {
  margin-bottom: 20px;
}

.send_new_link_section strong {
  font-size: 18px;
}

.send_new_link_section {
  padding-top: 10px;
}

.send_new_link_message_section {
  display: flex;
  padding: var(--Space-Padding-paddingSM, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
}

.send_new_link_message_section_title {
  color: var(--Colors-Mindset-Site-Dark-Blue, #3e3e56);
  font-family: var(--Components-Modal-Global-fontFamily, Raleway);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.send_new_link_message_section_content {
  align-self: stretch;
  color: var(--Colors-Mindset-Site-Dark-Blue, #3e3e56);
  font-family: var(--Components-Modal-Global-fontFamily, Raleway);
  font-size: 14px;
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: normal;
}

.unique_link_placeholder {
  color: var(--Colors-Neutral-Text-colorTextDisabled, rgba(0, 0, 0, 0.25));
  font-family: var(--Components-Modal-Global-fontFamily, Raleway);
  font-size: 14px;
  font-style: normal;
  font-weight: var(--Typography-Font-Weight-fontWeightNormal, 400);
  line-height: normal;
}

.sent_messages_section p {
  margin: 0;
  padding: 0;
}

.sent_messages_section > *:not(:last-child) {
  display: block;
  margin-bottom: 10px;
}

.secure_registration_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 300ms;
}

.reverse_button_order {
  flex-direction: row-reverse;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
